import { Theme } from '@mui/material';
import { createTheme, ThemeProvider as TP } from '@mui/material/styles';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      light: '#EDFFFD',
      main: '#31b5a7',
      dark: 'rgba(26, 127, 116, 1)'
    },
    secondary: {
      main: '#fff',
      dark: '#F4F4F4',
    },
  },
});

export const ThemeProvider = TP;
import React from "react";

import { NavLink } from "react-router-dom";
import { Box, IconButton, Link } from "@mui/material";

import {
  AiOutlineMenu as MenuIcon,
  AiOutlineClose as CloseIcon,
} from "react-icons/ai";

import Button from "./common/Button";
import logo from "../assets/Logos/f.png";

const MobileNav = () => {
  const styles = {
    links: {
      textDecoration: "none",
      color: "#fff",
      fontWeight: "600",
    },
  };
  return (
    <nav
      className="flex flex-col items-start hide-on-pc px-6 py-3"
      style={{
        position: "fixed",
        backgroundColor: "var(--primary-color)",
        width: "100%",
        zIndex: "200",
        // borderTop: "1px solid"
      }}
    >
      <Link
        href="/#home"
        sx={{
          ...styles.links,
          margin: "10px 0px",
        }}
      >
        Home
      </Link>

      <Link
        href="/#about-us"
        sx={{
          ...styles.links,
          margin: "10px 0px",
        }}
      >
        About Us
      </Link>

      <Link
        to="/#contact-us"
        href="mailto:contact@commodify.co"
        style={{
          ...styles.links,
          marginRight: "40px",
        }}
        className="hide-on-mobile"
      >
        Contact Us
      </Link>

      {/* <NavLink
        to="/blog"
        style={{
          ...styles.links,
          margin: "10px 0px",
        }}
      >
        Our Blog
      </NavLink> */}
    </nav>
  );
};

const DesktopNav = ({ isOpen = false, setIsOpen = () => {} }) => {
  const styles = {
    links: {
      textDecoration: "none",
      // color: "#fff",
      fontWeight: "500",
    },
  };
  const toggleMobileMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="flex flex-row items-center">
      <NavLink
        to="/#home"
        style={{
          ...styles.links,
          marginLeft: "40px",
          marginRight: "40px",
        }}
        className="hide-on-mobile"
      >
        Home
      </NavLink>

      <NavLink
        to="/#about-us"
        style={{
          ...styles.links,
          marginRight: "40px",
        }}
        className="hide-on-mobile"
      >
        About Us
      </NavLink>

      <NavLink
        to="/#how-it-works"
        style={{
          ...styles.links,
          marginRight: "40px",
        }}
        className="hide-on-mobile"
      >
        How It Works
      </NavLink>

      <NavLink
        to="/#"
        style={{
          ...styles.links,
          marginRight: "0px",
        }}
        className="hide-on-mobile"
      >
        Blog
      </NavLink>

    
      <IconButton
        className="hide-on-pc"
        onClick={toggleMobileMenu}
        sx={{
          color: "var(--primary-color)",
          fontSize: "26px",
        }}
      >
        {!isOpen ? (
          <MenuIcon style={{ fontSize: "26px" }} />
        ) : (
          <CloseIcon style={{ fontSize: "26px" }} />
        )}
      </IconButton>
    </nav>
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box
        component="header"
        className="flex flex-row items-center justify-around bg-white-500 py-5"
        sx={{
          px: {
            xs: "20px",
            md: "25px",
          },
          justifyContent: {
            xs: "space-between",
            md: "space-between",
          },
        }}
      >
        <Box
          component="div"
          className="flex flex-row items-center"
          sx={{
            flexDirection: {
              md: "row-reverse",
              xs: "row",
            },
          }}
        >
          <DesktopNav isOpen={isOpen} setIsOpen={setIsOpen} />
          <Link href="/">
            <img src={logo} alt="logo" width={150} />
          </Link>
        </Box>

        <div className="flex flex-row items-center">
          <Button
            href="mailto:contact@commodify.co"
            value="Contact Us"
            sx={{
              textAlign: "center",
              padding: "8px 16px",
              boxShadow: "none",
              borderRadius: "2px",
              ml: 5,
            }}
          />
        </div>
      </Box>

      {isOpen && <MobileNav />}
    </React.Fragment>
  );
};

export default Header;

import Button, { ButtonProps } from "@mui/material/Button";

type Props =
  | {
      value?: any;
      sx?: any;
      type?: any;
    }
  | ButtonProps;

function CustomButton(props: Props) {
  const { sx, value, ...rest } = props;
  const customSx = sx || {};
  return (
    <Button
      variant="contained"
      {...rest}
      sx={{
        color: "white",
        fontWeight: 600,
        fontSize: "14px",
        textTransform: "capitalize",
        padding: "20px 30px",
        borderRadius: 0,
        boxShadow: "0px 5px 20px rgba(108, 117, 125, 0)",
        ...customSx,
      }}
    >
      {value || ""}
    </Button>
  );
}

export default CustomButton;

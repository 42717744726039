import React from "react";

import { BsTelephone as PhoneIcon } from "react-icons/bs";
import { RiMapPin5Line as MapPinIcon } from "react-icons/ri";
import { IoMailOpenOutline as MailIcon } from "react-icons/io5";

import { Box, Link, Stack } from "@mui/material";

import logo from "../assets/logo 1.png";

import {
  RiInstagramFill as InstagramIcon,
  RiFacebookFill as FacebookIcon,
  RiTwitterFill as TwitterIcon,
} from "react-icons/ri";

const Footer = () => {
  return (
    <Box
      component="footer"
      className="flex flex-row flex-wrap items-stretch"
      sx={{
        justifyContent: {
          md: "space-between",
          xs: "center",
        },
      }}
    >
      <Stack
        component="section"
        className="justify-between my-4 mx-3 flex-grow"
        sx={{
          maxWidth: "380px",
          alignItems: {
            xs: "center",
            md: "flex-start",
          },
        }}
      >
        <div className="flex flex-col">
          <Link href="/">
            <img src={logo} alt="logo" width={150} />
          </Link>

          <p className="mt-3">
            Commodify provides access to financial services for agro
            commodity exporters in Sub-Saharan Africa
          </p>

          {/* <nav
            className="flex flex-row my-3 justify-between"
            style={{
              width: "150px",
            }}
          >
            <Link className="icon" href="https://www.instagram.com/commodifyafrica_">
              <InstagramIcon />
            </Link>
            <Link href="https://web.facebook.com/commodifyafrica" className="icon">
              <FacebookIcon />
            </Link>
            <Link
              href="https://twitter.com/commodifyafrica"
              className="icon"
              style={{
                marginRight: 0,
              }}
            >
              <TwitterIcon />
            </Link>
          </nav> */}
        </div>

        <Box
          component="span"
          sx={{
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}
        >
          2022. Commodify Africa Limited. All Rights Reserved
        </Box>
      </Stack>

      <div className="flex flex-col items-start justify-start mr-4 my-4">
        <h4>Contact Us</h4>

        {/* <Link
          href="tel:+2349084759645"
          className="flex items-center"
          sx={{
            mb: 2,
          }}
        >
          <span className="mr-4">
            <PhoneIcon />
          </span>
          <span>+234 908 475 9645</span>
        </Link> */}

        <Link
          href="mailto:contact@commodify.co"
          className="flex items-center"
          sx={{
            mb: 2,
          }}
        >
          <span className="mr-4">
            <MailIcon />
          </span>
          <span>contact@commodify.co</span>
        </Link>
        
        <nav
            className="flex flex-row my-3 justify-between"
            style={{
              width: "150px",
            }}
          >
            <Link className="icon" href="https://www.instagram.com/commodifyafrica_">
              <InstagramIcon />
            </Link>
            <Link href="https://web.facebook.com/commodifyafrica" className="icon">
              <FacebookIcon />
            </Link>
            <Link
              href="https://twitter.com/commodifyafrica"
              className="icon"
              style={{
                marginRight: 0,
              }}
            >
              <TwitterIcon />
            </Link>
          </nav>
      </div>
      
      <div className="flex flex-col items-start justify-start ml-4 my-4">
        <h4>Information</h4>
        <Link
          href="#"
          className="flex items-center"
          sx={{
            mb: 2,
          }}
        >
          Blog
        </Link>

        <Link
          href="#"
          className="flex items-center"
          sx={{
            mb: 2,
          }}
        >
          About Us
        </Link>

        <Link
          href="#"
          className="flex items-center"
          sx={
            {
              // flexGrow: 1,
            }
          }
        >
          Our Partners
        </Link>
      </div>
    </Box>
  );
};

export default Footer;

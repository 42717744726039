import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";

// Import Utility Components
import SuspenseFallback from "./components/SuspenseFallback";
import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";
// import Can from "./utils/rbac/Can";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages
import Index from "./pages/Index";

// Import Layout Components for Pages
const HomeMain: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./layouts/HomeMain")
);

// create a default container so we can override the styles
const ToastContainer = (props = {}) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Router>
            <Switch>
              <Route path="/" element={<Index />}>
                <Route index element={<HomeMain />} />
              </Route>

              {/* DASHBOARD
              <Route path="/dashboard" element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route
                  path="edit-profile"
                  element={<DashboardIndexSection />}
                />
              </Route> */}

              <Route path="*" element={<NoPage />} />
            </Switch>
          </Router>
          <ToastContainer />
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
